import * as React from 'react';
import { useEffect, useRef, useState } from 'react'
import styled from 'styled-components';

import { Content } from './PostTemplate';
import { postContact } from '../../utils/http';
import { unescape } from '../../utils/htmlEntities';
import Modal from './Modal';

type Props = {
  title: string;
  content?: string;
};

const PageTemplate = ({ title, content }: Props) => {
  const [form, setForm] = useState<any>(null);
  const [valid, setValid] = useState<boolean>(false);
  const [message, setMessage] = useState<any>(null);
  const contentRef = useRef<any>(null);

  const handleSubmit = (event: any) => {
    const form = event.target;
    event.preventDefault();
    if (form.querySelectorAll('input[name="_wpcf7"]').length) {
      const id = form.querySelector('input[name="_wpcf7"]').getAttribute('value');
      postContact(id, new FormData(form))
        .then(res => {
          setValid(res.data.status === 'mail_sent');
          setMessage(res.data.message);
        })
        .catch(err => {
          console.warn(err)
          setValid(false);
          setMessage('通信エラーです');
        });
    }
  };

  useEffect(() => {
    // form
    if (content) {
      setTimeout(() => {
        const wpcf7 = contentRef.current.querySelector('form.wpcf7-form');
        if (wpcf7 && wpcf7.length) {
          const formRef = contentRef.current.querySelector('form.wpcf7-form');
          setForm(formRef);
        }

        // すべてのwp-blogへのリンク先を修正
        contentRef.current.querySelectorAll('a').forEach((dom: HTMLAnchorElement) => {
          const matches = /^https:\/\/wp-blog\.yuhiisk\.com(.+)/.exec(dom.href);
          if (matches != null && matches[0]) {
            // TODO: site urlをconfigから取得する
            dom.setAttribute('href', `https://blog.yuhiisk.com${matches[1]}`);
          }
        });
      }, 100);
    }
  }, []);

  useEffect(() => {
    if (form) {
      // イベント設定
      form.addEventListener('submit', handleSubmit);
    }

    return () => {
      if (form) {
        // イベント破棄
        form.removeEventListener('submit', handleSubmit);
      }
    };
  }, [form]);

  return (
    <section className="section section--gradient">
      <ContentWrapper>
        <div className="columns">
          <Wrapper>
            <Title>
              {unescape(title)}
            </Title>
            <Content ref={contentRef} dangerouslySetInnerHTML={{ __html: content }} />
            {!valid && message && <Error>{message}</Error>}
          </Wrapper>
        </div>
      </ContentWrapper>
      {(valid && message) && (
        <Modal onClose={() => setMessage(null)}>
          <article className="message is-success">
            <div className="message-body">
              {message}
            </div>
          </article>
        </Modal>
      )}
    </section>
  )
}

export default PageTemplate;


const ContentWrapper = styled.div`
  position: relative;
  margin: 0 auto;
`;

const Wrapper = styled.div`
  max-width: 700px;
  margin: 0 auto;
`;

const Title = styled.h1`
  line-height: 1.5;
  margin-bottom: .5em;
  font-size: 2.2em;
  font-weight: bold;
  word-break: break-word;
`;

const Error = styled.p`
  padding: 5px 10px;
  background-color: #fff5f7;
  color: #cd0930;
`
