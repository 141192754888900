import * as React from 'react';
import { graphql } from 'gatsby';

import SEO from '../components/SEO';
import Layout from '../components/Layout';
import PageTemplate from '../components/templates/PageTemplate';

type Props = {
  data: any
};

const Page = ({ data }: Props) => {

  const { wordpressPage: page } = data

  return (
    <Layout>
      <SEO
        title={`${page.title}`}
        permalink={page.link}
        description={page.excerpt}
        article={false}
      />
      <PageTemplate title={page.title} content={page.content} />
    </Layout>
  )
}

export default Page;

export const pageQuery = graphql`
  query PageById($id: String!) {
    wordpressPage(id: { eq: $id }) {
      title
      content
      excerpt
      link
    }
  }
`
