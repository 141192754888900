import axios, { AxiosResponse } from 'axios';

/**
 * setup
 */
export const httpClient = axios.create({
  baseURL: 'https://wp-blog.yuhiisk.com',
  // auth: {
    // username: 'yuhiisk',
    // password: 'VOrP 6BYZ iq4A nkDV 2sTW 7g1E',
    // password: 'wsCR@a9dK0eT&R6OW5'
  // },
  // withCredentials: true,
  headers: {
    // 'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
    // 'X-Requested-With': 'XMLHttpRequest'
    // 'Authorization': `Basic ${window.btoa('yuhiisk:wsCR@a9dK0eT&R6OW5')}`
  }
});

export const postContact = (id: number, payload: any): Promise<AxiosResponse> => {
  return httpClient.post(`/wp-json/contact-form-7/v1/contact-forms/${id}/feedback`, payload);
};
