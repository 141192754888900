import * as React from 'react';
import { ReactNode, useState } from 'react';
import * as ReactDOM from 'react-dom';

type Props = {
  children?: ReactNode;
  onClose: () => void;
};

const Modal = ({ children, onClose }: Props) => {
  const [root] = useState<Element>(document.body as Element);

  return ReactDOM.createPortal(
    <div className="modal" style={{ display: 'flex' }}>
      <div className="modal-background" onClick={onClose} />
      <div className="modal-content">
        {children}
      </div>
      <button onClick={onClose} className="modal-close is-large" aria-label="close" />
    </div>,
    root
  );
};

export default Modal;